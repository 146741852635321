import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { selectShowReportFlag } from '@/api/mchGauge';
import wx from 'weixin-js-sdk';
import { mapGetters } from 'vuex';
import utils from '@/utils/utils';
import { find } from '@/api/evaluation';
import { list, importDowm } from '@/api/gaugePlan';
import { SHARE } from '@/utils/share';
import { Toast } from 'vant';
var myChart = '';
export default {
  data: function data() {
    return {
      showFlag: false,
      shareLogoList: [],
      isImgMaskShow: false,
      isMaskShow: false,
      showMore: false,
      evaluationId: '',
      attribute: {},
      jsonResult: [],
      complexs: [],
      adList: [],
      paymentSn: '',
      showSub: true,
      shareBg: '',
      showCode: false,
      qrcodeName: '',
      qrcode: '',
      subCategoryId: '/',
      recommendList: [],
      shareBox: false,
      sharebgContent: '',
      isMaskbotton: false,
      advertising: null,
      userType: '',
      typeStatus: '0',
      activeNames: 'show',
      groupOne: [],
      groupTwo: [],
      groupThree: [],
      name: '',
      reportFlag: '1',
      reportFlagCollapse: 1,
      reportUrl: '',
      showReportUrl: false,
      isComplete: true,
      loading: null,
      downloadFlag: false,
      // 是否可以下载
      downLoading: false,
      isIso: false,
      show: false
    };
  },
  components: {},
  computed: _objectSpread({}, mapGetters(['mchId'])),
  created: function created() {
    this.isIso = utils.isIos();
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(225, 225, 225, 0.9)'
    });
    this.evaluationId = this.$route.query.evaluationId;
    this.loadGauge();
    this.loadFind();
  },
  filters: {
    formatDuraton: function formatDuraton(time) {
      if (time > -1) {
        var hour = Math.floor(time / 3600);
        var min = Math.floor(time / 60) % 60;
        var sec = time % 60;
        if (hour < 10) {
          time = '';
        } else {
          time = '';
        }
        if (min < 10) {
          time += '0';
        }
        time += min + ':';
        if (sec < 10) {
          time += '0';
        }
        time += sec;
      }
      return time;
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    // 下载报告
    onDwon: function onDwon() {
      var _this2 = this;
      // SHARE(
      //   location.href,
      //   {
      //     title: this.attribute.title,
      //     desc: this.attribute.subTitle,
      //     link:
      //       utils.host() + `/#/gauge/result?evaluationId=${this.attribute.id}`,
      //     imgUrl: this.attribute.thumbnail
      //   },
      //   function (res) {}
      // )
      this.showReportUrl = false;
      this.downLoading = true;
      importDowm(this.attribute.planId).then(function (res) {
        _this2.showReportUrl = true;
        _this2.downLoading = false;
        var url = res.content;
        var fileName = '报告.pdf';
        //console.log(fileName)
        // 获取文件名
        // 文件地址

        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName); // 文件名
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        }, 1000);
      }).catch(function (err) {});
    },
    onClickdwon: function onClickdwon() {
      var _this3 = this;
      this.$copyText(this.reportUrl).then(function (e) {
        _this3.$message({
          message: '报告下载地址已粘贴到剪切板，请复制到浏览器地址栏中下载!',
          type: 'success'
        });
      }).catch(function (e) {
        console.log('复制报告地址失败', e);
        _this3.$message({
          message: '该浏览器不支持自动复制',
          type: 'warning'
        });
      });
    },
    onIosDown: function onIosDown() {
      var _this4 = this;
      Toast.loading({
        duration: 0,
        // 持续展示 toast
        forbidClick: true,
        message: '下载中...'
      });
      this.showReportUrl = false;
      this.downLoading = true;
      importDowm(this.attribute.planId).then(function (res) {
        _this4.showReportUrl = true;
        _this4.downLoading = false;
        _this4.show = true;
        _this4.reportUrl = res.content;
        Toast.clear();
      }).catch(function (err) {});
    },
    loadGauge: function loadGauge() {
      var _this5 = this;
      var params = new URLSearchParams();
      params.append('pageNum', 1);
      params.append('pageSize', 20);
      params.append('mchId', this.mchId);
      list(params).then(function (response) {
        response.data.data.forEach(function (item) {
          if (!item.complete) {
            _this5.isComplete = false;
          }
        });
      }).catch(function (error) {});
    },
    resultButtonClick: function resultButtonClick() {
      if (this.isComplete) {
        var popup = confirm('您已完成所有测评任务，即将退出页面！');
        if (popup) {
          if (utils.isWeiXin()) {
            wx.closeWindow();
          } else {
            window.opener = null;
            // window.open('about:blank', '_self')
            // window.close()
            this.$router.replace({
              path: '/assessment/list'
              // query: { isMaskType: 1 }
            });
          }
        }
      } else {
        this.$router.replace({
          path: '/assessment/list',
          query: {
            isMaskType: 1
          }
        });
      }
    },
    loadFind: function loadFind() {
      var _this6 = this;
      var _this = this;
      find(this.evaluationId).then(function (response) {
        console.log(response);
        _this6.downloadFlag = response.data.downloadFlag;
        _this.attribute = response.data.evaluation;
        _this.jsonResult = response.data.results;
        _this6.name = response.data.name;
        _this6.reportFlag = response.data.reportFlag;
        selectShowReportFlag(response.data.evaluation.mchGaugeId).then(function (res) {
          _this6.showFlag = res.data;
        });
        console.log(_this.jsonResult);
        _this.jsonResult.forEach(function (item, index) {
          if (item.groupType == 0) {
            _this.groupOne.push(item);
          } else if (item.groupType == 1) {
            _this.groupTwo.push(item);
          } else if (item.groupType == 2) {
            _this.groupThree.push(item);
          }
          if (item.type == 2) {
            setTimeout(function () {
              item.content = JSON.parse(item.content);
              // 基于准备好的dom，初始化echarts实例
              myChart = _this.$echarts.init(document.getElementById('myChart' + index));
              // 绘制图表
              myChart.setOption(item.content);
            }, 200);
          }
        });
        setTimeout(function () {
          _this.loading.close();
        }, 300);
      }).catch(function (error) {
        setTimeout(function () {
          _this.loading.close();
        }, 300);
      });
    },
    onClick: function onClick() {
      this.$router.replace({
        path: '/'
      });
    }
  }
};