var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recordPage", attrs: { id: "target" } }, [
    _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "text_title" }, [
        _vm._v("请读第" + _vm._s(_vm.paraIndex) + "段话"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.paragraph, function (item, index) {
          return _c("div", { key: index }, [
            _vm.paraIndex === index + 1
              ? _c("div", { domProps: { innerHTML: _vm._s(item.content) } })
              : _vm._e(),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "paraIndex" }, [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v(_vm._s(_vm.paraIndex)),
        ]),
        _vm._v(" / "),
        _c("span", [_vm._v(_vm._s(_vm.paragraph.length))]),
      ]),
    ]),
    _vm._v(" "),
    !_vm.isRecording
      ? _c(
          "div",
          {
            staticClass: "startRecordBtn",
            on: {
              click: function ($event) {
                _vm.recordStart()
                _vm.startcount()
                _vm.startRecording()
              },
            },
          },
          [
            _c("div", { staticClass: "answer-footer-commit" }, [
              !_vm.isRecording ? _c("span", [_vm._v("开始朗读")]) : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "startRecordBtn" }, [
      _vm.isRecording
        ? _c("div", { staticClass: "answer-footer-commit" }, [_vm._m(0)])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "answer-footer-time" }, [_vm._v(_vm._s(_vm.ti))]),
    _vm._v(" "),
    _vm.isRecording
      ? _c("div", { staticClass: "tapeClass" }, [
          _vm.paraIndex !== _vm.paragraph.length
            ? _c(
                "div",
                {
                  staticClass: "answer-footer-commit",
                  on: {
                    click: function ($event) {
                      _vm.stopTimer()
                      _vm.startcount()
                      _vm.readNextPara()
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("点击读第" + _vm._s(_vm.paraIndex + 1) + "段"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.paraIndex === _vm.paragraph.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.loading,
                      expression: "loading",
                      modifiers: { fullscreen: true, lock: true },
                    },
                  ],
                  staticClass: "answer-footer-commit",
                  staticStyle: { "background-color": "#2CC74E" },
                  on: {
                    click: function ($event) {
                      _vm.stopTimer()
                      _vm.stopTimeAndSubmit()
                    },
                  },
                },
                [_c("span", [_vm._v("点击提交")])]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isRecording
      ? _c("div", { staticClass: "botClass" }, [
          _c(
            "div",
            {
              staticStyle: {
                flex: "1",
                "text-align": "center",
                "padding-right": "40px",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paraIndex !== 1,
                      expression: "paraIndex !== 1",
                    },
                  ],
                  staticClass: "rerecordPre",
                  on: {
                    click: function ($event) {
                      _vm.stopTimer()
                      _vm.startcount()
                      _vm.rerecordPre()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/mxs_images/prePara.png"),
                      alt: "",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paraIndex !== 1,
                      expression: "paraIndex !== 1",
                    },
                  ],
                  staticStyle: { color: "#9E9E9E" },
                },
                [_vm._v("重录第上一段")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                flex: "1",
                "text-align": "center",
                "padding-left": "40px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "rerecordNext",
                  on: {
                    click: function ($event) {
                      _vm.stopTimer()
                      _vm.startcount()
                      _vm.rerecordThe()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/mxs_images/thePara.png"),
                      alt: "",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#9E9E9E" } }, [
                _vm._v("重录本段"),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticStyle: { height: "100px" } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "recording" }, [_vm._v("录音中")]),
      _vm._v(" "),
      _c("img", {
        staticClass: "recordingIcon",
        attrs: { src: require("../../assets/mxs_images/recording.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }