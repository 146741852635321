var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "answer-content" }, [
      _c(
        "div",
        { staticClass: "answer-content-image" },
        _vm._l(_vm.nowAnswer.options, function (item, index) {
          return _c(
            "div",
            {
              staticClass: "answer-content-imageBox",
              class: [
                item.id == _vm.chooseId ? "answer-content-image-article" : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.chooseAnswer(index, item)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(item.name))])]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }