var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _vm._v("\n    正在跳转中...\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }