"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.getInfo = getInfo;
exports.getPlan = getPlan;
exports.getSysMchTitleInfo = getSysMchTitleInfo;
exports.getTopic = getTopic;
exports.iframeBind = iframeBind;
exports.iframeGetSysMchTitleInfo = iframeGetSysMchTitleInfo;
exports.iframeList = iframeList;
exports.importDowm = importDowm;
exports.list = list;
exports.perList = perList;
exports.perdList = perdList;
exports.teamList = teamList;
exports.unfinish = unfinish;
var _request = _interopRequireDefault(require("@/utils/request"));
function iframeList(params) {
  return (0, _request.default)({
    url: '/gaugePlan/getGaugeList',
    method: 'get',
    params: params
  });
}
function list(params) {
  return (0, _request.default)({
    url: '/gaugePlan/list',
    method: 'get',
    params: params
  });
}
function create(params) {
  return (0, _request.default)({
    url: '/member/gaugePlan/create',
    method: 'post',
    params: params
  });
}
function iframeGetSysMchTitleInfo(params) {
  return (0, _request.default)({
    url: '/gaugePlan/getTitleInfo',
    method: 'get',
    params: params
  });
}
function getSysMchTitleInfo(params) {
  return (0, _request.default)({
    url: '/admin/sysMchTitle/getSysMchTitleInfo',
    method: 'get',
    params: params
  });
}
function iframeBind(params) {
  return (0, _request.default)({
    url: '/gaugePlan/bind',
    method: 'post',
    params: params
  });
}

// 团体测评列表结果
function teamList(params) {
  return (0, _request.default)({
    url: '/member/gaugePlan/find/' + params,
    method: 'get'
  });
}

// 个人测评列表
function perList(params) {
  return (0, _request.default)({
    url: '/member/gaugePlan/listPersonal',
    method: 'get',
    params: params
  });
}

// 个人测评列表结果
function perdList(params) {
  return (0, _request.default)({
    url: '/member/gaugePlan/findPersonal/' + params,
    method: 'get'
  });
}

// 获取登录成员信息
function getInfo() {
  return (0, _request.default)({
    url: '/member/companyEmployee/employeeInfo',
    method: 'get'
  });
}

// 根据测评计划获取专题
function getTopic(params) {
  return (0, _request.default)({
    url: '/gaugePlan/getTopic',
    method: 'get',
    params: params
  });
}

// 下载报告
function importDowm(params) {
  return (0, _request.default)({
    url: '/member/gaugePlan/downloadReport/' + params,
    method: 'get'
  });
}

// 获取未完成数量
function unfinish(params) {
  return (0, _request.default)({
    url: '/member/gaugePlan/unfinish',
    method: 'get'
  });
}

//获取详情
function getPlan(id) {
  return (0, _request.default)({
    url: '/admin/gaugePlan/find/' + id,
    method: 'get'
  });
}