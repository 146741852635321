var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _vm._l(_vm.componentsList, function (item) {
        return _c(
          "div",
          { key: item.id },
          [_c(item.name, { tag: "component", attrs: { content: item } })],
          1
        )
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "100%",
          height: "60px",
          "background-color": "white",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }