var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "result" },
    [
      _c(
        "div",
        { staticClass: "result-headerTitleBox", style: { zIndex: 2 } },
        [
          _c(
            "span",
            { staticClass: "result-headerTitle", style: { zIndex: 2 } },
            [_vm._v(_vm._s(_vm.attribute.title))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "result-headerSubtitleBox", style: { zIndex: 2 } },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("span", { staticClass: "result-headerSubTitle" }, [
                _vm._v(_vm._s(_vm.attribute.subTitle)),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "result-bg" }, [
        _c("img", {
          style: { zIndex: 1 },
          attrs: {
            width: "100%",
            height: "150",
            src: require("../../assets/mxs_images/img_top.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "result-content", style: { zIndex: 2 } }, [
        _vm.attribute.completeDate
          ? _c("div", { staticClass: "result-content-information-Box" }, [
              _c("span", { staticClass: "result-content-information-Name" }, [
                _vm._v(
                  "测评人员：" +
                    _vm._s(_vm.reportFlag ? _vm.name : _vm.attribute.nickName)
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "result-content-information-Date" }, [
                _vm._v(
                  "完成时间：" +
                    _vm._s(
                      _vm._f("datetimeFormatter")(_vm.attribute.completeDate)
                    )
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "result-content-information-Time" }, [
                _vm._v(
                  "所用时长：" +
                    _vm._s(_vm._f("formatDuraton")(_vm.attribute.seconds)) +
                    "\n        "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.reportFlag &&
                  _vm.attribute.completeDate &&
                  !_vm.showFlag,
                expression:
                  "!reportFlag && attribute.completeDate && !showFlag",
              },
            ],
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.groupOne.length > 0,
                    expression: "groupOne.length > 0",
                  },
                ],
                staticClass: "result-Content-Header",
              },
              [_vm._m(1)]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "result-Content-First" },
              [
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.reportFlagCollapse,
                      callback: function ($$v) {
                        _vm.reportFlagCollapse = $$v
                      },
                      expression: "reportFlagCollapse",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { name: 1 } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "result-content-information-Content-Name",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "result-content-information-Content-Line",
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "result-content-information-Content-Title",
                                },
                                [_vm._v("测评已完成")]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "result-html" }, [
                          _c("p", { staticStyle: { "line-height": "2" } }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "12pt",
                                  "letter-spacing": "0.5pt",
                                },
                              },
                              [_vm._v("您好，您已完成测评，感谢您的参与！")]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "resultButtonBox" }, [
                          _c(
                            "div",
                            {
                              staticClass: "resultButton",
                              on: {
                                click: function ($event) {
                                  return _vm.resultButtonClick()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.isComplete ? "完成任务" : "继续完成"
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.reportFlag && _vm.attribute.completeDate && _vm.showFlag,
                expression: "reportFlag && attribute.completeDate && showFlag",
              },
            ],
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.groupOne.length > 0,
                    expression: "groupOne.length > 0",
                  },
                ],
                staticClass: "result-Content-Header",
              },
              [_vm._m(2)]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "result-Content-First" },
              _vm._l(_vm.jsonResult, function (item, index) {
                return item.groupType == 0
                  ? _c("div", { key: index }, [
                      item.type != 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-collapse",
                                {
                                  model: {
                                    value: index,
                                    callback: function ($$v) {
                                      index = $$v
                                    },
                                    expression: "index",
                                  },
                                },
                                [
                                  _c(
                                    "el-collapse-item",
                                    { attrs: { name: index } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "result-content-information-Content-Name",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "result-content-information-Content-Line",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "result-content-information-Content-Title",
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "result-html",
                                        domProps: {
                                          innerHTML: _vm._s(item.content),
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.type == 2
                        ? _c("div", {
                            staticClass: "result-content-table",
                            attrs: { id: "myChart" + index },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e()
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.groupTwo.length > 0,
                    expression: "groupTwo.length > 0",
                  },
                ],
                staticClass: "result-Content-unlock-Second",
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _vm._l(_vm.jsonResult, function (item, index) {
                  return item.groupType == 1
                    ? _c("div", { key: index }, [
                        item.type != 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-collapse",
                                  [
                                    _c(
                                      "el-collapse-item",
                                      [
                                        _c("template", { slot: "title" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "result-content-information-Content-Name",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "result-content-information-Content-Line",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "result-content-information-Content-Title",
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "result-html",
                                          domProps: {
                                            innerHTML: _vm._s(item.content),
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.type == 2
                          ? _c("div", {
                              staticClass: "result-content-table",
                              attrs: { id: "myChart" + index },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e()
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.groupThree.length > 0,
                    expression: "groupThree.length > 0",
                  },
                ],
                staticClass: "result-Content-unlock-Second",
              },
              [
                _vm._m(4),
                _vm._v(" "),
                _vm._l(_vm.jsonResult, function (item, index) {
                  return item.groupType == 2
                    ? _c("div", { key: index }, [
                        item.type != 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-collapse",
                                  [
                                    _c(
                                      "el-collapse-item",
                                      [
                                        _c("template", { slot: "title" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "result-content-information-Content-Name",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "result-content-information-Content-Line",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "result-content-information-Content-Title",
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "result-html",
                                          domProps: {
                                            innerHTML: _vm._s(item.content),
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.type == 2
                          ? _c("div", {
                              staticClass: "result-content-table",
                              attrs: { id: "myChart" + index },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e()
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "two-btn" }, [
              _c(
                "div",
                { staticClass: "lt" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resultButtonClick()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.isComplete ? "退出测评" : "继续测评") +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lt" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onClick()
                        },
                      },
                    },
                    [_vm._v("回到首页")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("van-dialog", {
        attrs: {
          title: "是否确定复制链接，到浏览器打开下载？",
          "show-cancel-button": "",
        },
        on: { confirm: _vm.onClickdwon },
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "result-headerImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/icon_top.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "result-Content-Header-Img" }, [
      _c("span", { staticClass: "result-Content-Header-Img—Title" }, [
        _vm._v("测评结果"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "result-Content-Header-Img" }, [
      _c("span", { staticClass: "result-Content-Header-Img—Title" }, [
        _vm._v("测评结果"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "result-Content-unlock-Second-Header" }, [
      _c("div", { staticClass: "result-Content-Second-Name-ImgHeader" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/mxs_images/icon_jgfx.png"),
            alt: "",
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "result-Content-Second-Name-Title" }, [
        _vm._v("结果分析"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "result-Content-unlock-third-Header" }, [
      _c("div", { staticClass: "result-Content-Second-Name-ImgHeader" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/mxs_images/icon_fx.png"),
            alt: "",
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "result-Content-third-Name-Title" }, [
        _vm._v("改善建议"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }