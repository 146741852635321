"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _silentLogin = require("@/api/silentLogin");
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    return {};
  },
  created: function created() {
    var _this = this;
    (0, _auth.removeToken)();
    localStorage.setItem('testUrl', this.$route.query.redirect_url);
    localStorage.setItem('testQuery', this.$route.query.evaluationId);
    (0, _silentLogin.silentLogin)(this.$route.query.code).then(function (res) {
      if (res.data.token) {
        (0, _auth.setToken)(res.data.token);
        // localStorage.setItem('companyEmployee', res.data.companyEmployee);
        // localStorage.setItem('loginType', 0);
        // localStorage.setItem('isAllFinish', 1)
      }
    }).catch(function (err) {});
    setTimeout(function () {
      _this.$router.push({
        path: _this.$route.query.redirect_url,
        query: {
          evaluationId: _this.$route.query.evaluationId
        }
      });
    }, 1000);
  },
  destroyed: function destroyed() {
    location.reload();
  },
  methods: {}
};