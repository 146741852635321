"use strict";

var _interopRequireWildcard = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/defineProperty.js"));
var faceapi = _interopRequireWildcard(require("face-api.js"));
var _validate = require("@/utils/validate");
var _utils = _interopRequireDefault(require("../../utils/utils.js"));
var _row = _interopRequireDefault(require("element-ui/packages/row/src/row"));
var _col = _interopRequireDefault(require("element-ui/packages/col/src/col"));
var _user = require("@/api/user");
var _companyEmployee = require("@/api/companyEmployee");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'evalLogin',
  props: ['title', 'setName', 'setCode'],
  data: function data() {
    var _this = this,
      _ref;
    var validateUsername = function validateUsername(rule, value, callback) {
      if (value.length < 1) {
        callback(new Error((_this.setName || '账号') + '不能为空'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error((_this.setCode || '密码') + '不能少于6位'));
      } else {
        callback();
      }
    };
    return _ref = {
      facailRegister: false,
      register: false,
      loginForm: {
        username: '',
        password: '',
        captcha: '',
        captchaUid: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        captcha: [{
          required: true,
          trigger: 'blur',
          message: '验证码不能为空'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
      codeImg: '',
      faceId: "",
      visible: false
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "loading", false), "os", false), "thisVideo", null), "thisContext", null), "thisCancas", null), "videoWidth", 500), "videoHeight", 400), "postOptions", []), "CertCtl", ""), "loading", true), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "ids", []), "single", true), "multiple", true), "total", 0), "akworkerList", []), "workerTypeOptions", []), "title", ""), "open", false), "queryParams", {
      pageNum: 1,
      pageSize: 10
    }), "imgSrc", undefined), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "form", {}), "rules", {}), "files", {}), "fileName", ""), "fileList", []);
  },
  components: {
    ElCol: _col.default,
    ElRow: _row.default
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
        if (route.query && route.query.xuid) {
          this.redirect = this.redirect + '&xuid=' + route.query.xuid;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.loginForm.verifyCodeKey = _utils.default.getUUID();
    this.createCaptcha();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    handleRegister: function handleRegister() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          var submitForm = {};
          submitForm.username = _this2.loginForm.username;
          submitForm.password = _this2.loginForm.password;
          submitForm.mchId = _this2.$store.state.user.mchId;
          (0, _companyEmployee.register)(submitForm).then(function (res) {
            if (res.type == 'success') {
              _this2.$message.success(res.content);
            } else {
              ths.$message.error(res.content);
            }
            _this2.loading = false;
          });
        }
      });
    },
    forget: function forget() {
      this.$router.push('/password');
    },
    register: function register() {
      this.$router.push('/register');
    },
    // 图片验证码
    createCaptcha: function createCaptcha() {
      var _this3 = this;
      (0, _user.getRefresh)().then(function (res) {
        _this3.codeImg = res.data.captcha;
        _this3.loginForm.captchaUid = res.data.captchaUid;
      }).catch(function (err) {});
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this4 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this4.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this5 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          _this5.$store.dispatch('user/loginPassword', _this5.loginForm).then(function () {
            // 登录类型 1：账号密码登录
            localStorage.setItem('loginType', 0);
            _this5.loading = false;
            (0, _user.getFinishData)().then(function (data) {
              localStorage.setItem('isAllFinish', data.data.isAllFinish);
              if (data.data.isAllFinish == 1) {
                _this5.$router.push({
                  path: _this5.redirect || '/'
                });
              } else {
                _this5.$router.replace({
                  path: '/material'
                });
              }
            }).catch(function (err) {});
          }).catch(function () {
            _this5.loading = false;
            _this5.createCaptcha();
          });

          // this.$store
          //   .dispatch('user/login', this.loginForm)
          //   .then(() => {
          //     console.log('login success!!')
          //     this.createCaptcha()
          //     this.$router.push({ path: this.redirect || '/' })
          //     this.loading = false
          //   })
          //   .catch(() => {
          //     console.log('login error!!')
          //     this.createCaptcha()
          //     this.loading = false
          //   })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 切换登录
    checkLogin: function checkLogin() {
      this.register = false;
      // this.$router.push({ path: '/register' })
    },
    // 切换注册
    checkRegister: function checkRegister() {
      this.register = true;
      // this.$router.push({ path: '/register' })
    },
    // 切换人脸注册
    checkFacailRegister: function checkFacailRegister() {
      this.facailRegister = true;
    }
  }
};