var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container-eval" }, [
    !_vm.facailRegister
      ? _c(
          "div",
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "login-welcome" }, [
              !_vm.register ? _c("div", [_vm._v("欢迎登录")]) : _vm._e(),
              _vm._v(" "),
              _vm.register ? _c("div", [_vm._v("欢迎注册")]) : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.title || "心理测评系统")),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { staticClass: "login-infoBox" },
              [
                !_vm.register
                  ? _c(
                      "el-form",
                      {
                        ref: "loginForm",
                        staticClass: "login-form",
                        attrs: {
                          model: _vm.loginForm,
                          rules: _vm.loginRules,
                          autocomplete: "on",
                          "label-position": "left",
                        },
                      },
                      [
                        _c("div", { staticClass: "login-title" }, [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: require("./images/pc.png") },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-item-box" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c("span", { staticClass: "svg-container" }, [
                                  _c("img", {
                                    staticStyle: { height: "27px" },
                                    attrs: {
                                      src: require("./images/phone.png"),
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  ref: "username",
                                  attrs: {
                                    placeholder:
                                      "请输入" + (_vm.setName || "账号"),
                                    name: "username",
                                    type: "text",
                                    tabindex: "1",
                                    autocomplete: "on",
                                  },
                                  model: {
                                    value: _vm.loginForm.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginForm, "username", $$v)
                                    },
                                    expression: "loginForm.username",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Caps lock is On",
                              placement: "right",
                              manual: "",
                            },
                            model: {
                              value: _vm.capsTooltip,
                              callback: function ($$v) {
                                _vm.capsTooltip = $$v
                              },
                              expression: "capsTooltip",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-item-box" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "password" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "svg-container" },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "27px" },
                                          attrs: {
                                            src: require("./images/password.png"),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      key: _vm.passwordType,
                                      ref: "password",
                                      attrs: {
                                        type: _vm.passwordType,
                                        placeholder:
                                          "请输入" + (_vm.setCode || "密码"),
                                        name: "password",
                                        tabindex: "2",
                                        autocomplete: "on",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          _vm.capsTooltip = false
                                        },
                                      },
                                      nativeOn: {
                                        keyup: [
                                          function ($event) {
                                            return _vm.checkCapslock($event)
                                          },
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleLogin($event)
                                          },
                                        ],
                                      },
                                      model: {
                                        value: _vm.loginForm.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "loginForm.password",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "show-pwd",
                                        on: { click: _vm.showPwd },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class":
                                              _vm.passwordType === "password"
                                                ? "eye2"
                                                : "eye-open",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "loginButton",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleLogin },
                          },
                          [_vm._v("登录")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerBox" }, [
      _c("img", {
        staticClass: "login-topImg",
        attrs: { src: require("./images/login-topImg.png") },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "login-left-mainImg" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: require("./images/eval-loginbj2.png"),
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }