var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
    _vm.facial
      ? _c("div", { staticClass: "teach_box" }, [
          _vm.teach_one
            ? _c(
                "div",
                {
                  staticClass: "teach_one",
                  on: {
                    click: function ($event) {
                      return _vm.teachOne()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/mxs_images/teach_one.png"),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.teach_tow
            ? _c(
                "div",
                {
                  staticClass: "teach_tow",
                  on: {
                    click: function ($event) {
                      return _vm.teachTow()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/mxs_images/teach_tow.png"),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          { staticClass: "facial_box" },
          [
            _c("div", { staticClass: "facial_title" }, [
              _vm._v("AI多模态心理检测系统"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "facial_state" }, [
              _vm.countDown == 31
                ? _c("div", { staticClass: "facial_txt" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/mxs_images/camera.png") },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "txt" }, [
                      _vm._v("未检测到摄像头"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.reFacialRecogning && _vm.countDown != 31
                ? _c("div", { staticClass: "count_down" }, [
                    _vm._v(_vm._s(_vm.countDown)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("video", {
                ref: "video",
                staticStyle: {
                  "object-fit": "cover",
                  "border-radius": "1000px",
                },
                attrs: {
                  autoplay: "",
                  muted: "",
                  width: "100%",
                  height: "100%",
                },
                domProps: { muted: true },
              }),
              _vm._v(" "),
              _vm.reFacialRecogning
                ? _c("div", { staticClass: "complete" }, [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: require("@/assets/mxs_images/complete.png"),
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hint" }, [
              _vm._v(
                _vm._s(
                  _vm.reFacialRecogning
                    ? "识别完成"
                    : "请将面部置于取景框内，保持光线充足"
                )
              ),
            ]),
            _vm._v(" "),
            _vm.facialRecogning === false && _vm.reFacialRecogning === false
              ? _c(
                  "div",
                  {
                    staticClass: "photoUpload_btn",
                    on: {
                      click: function ($event) {
                        return _vm.startRecording()
                      },
                    },
                  },
                  [_vm._v("开始分析")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.reFacialRecogning
              ? _c(
                  "div",
                  {
                    staticClass: "photoUpload_btn",
                    on: { click: _vm.startRecording },
                  },
                  [_vm._v("重新分析")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.reFacialRecogning
              ? _c(
                  "div",
                  {
                    staticClass: "nextStep_btn",
                    on: {
                      click: function ($event) {
                        return _vm.next()
                      },
                    },
                  },
                  [_vm._v("下一步")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "上传图片",
                  visible: _vm.photoUpload,
                  "close-on-click-modal": false,
                  "show-close": false,
                  center: true,
                  width: "80%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.photoUpload = $event
                  },
                },
              },
              [
                _c("span", { staticClass: "agreement_content" }, [
                  _c("input", {
                    attrs: { type: "file" },
                    on: { change: _vm.handleFileUpload },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { type: "primary", round: "true" },
                        on: {
                          click: function ($event) {
                            _vm.photoUpload = false
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "上传视频",
                  visible: _vm.videoUpload,
                  "close-on-click-modal": false,
                  "show-close": false,
                  center: true,
                  width: "80%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.videoUpload = $event
                  },
                },
              },
              [
                _c("span", { staticClass: "agreement_content" }, [
                  _c("input", {
                    attrs: { type: "file" },
                    on: { change: _vm.handleFileUpload },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { type: "primary", round: "true" },
                        on: {
                          click: function ($event) {
                            _vm.videoUpload = false
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "拍照上传",
                  visible: _vm.photoVisible,
                  width: "80%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.photoVisible = $event
                  },
                  close: _vm.photoStop,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("video", {
                            ref: "video",
                            attrs: {
                              autoplay: "",
                              playsinline: "",
                              width: "200px",
                            },
                          }),
                          _vm._v(" "),
                          _c("canvas", {
                            ref: "canvas",
                            staticStyle: { display: "none" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "130px" },
                            attrs: { span: 4, offset: 8 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.takePhoto },
                              },
                              [_vm._v("拍照")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm.photo
                            ? _c("div", [
                                _c("h3", [_vm._v("拍照结果：")]),
                                _vm._v(" "),
                                _c("img", {
                                  attrs: {
                                    src: _vm.photo,
                                    alt: "Captured Photo",
                                    width: "200px",
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.submitPhoto },
                          },
                          [_vm._v("上传照片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.photoStop },
                          },
                          [_vm._v("关 闭")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "录像上传",
                  visible: _vm.videoVisible,
                  width: "80%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.videoVisible = $event
                  },
                  close: _vm.videoStop,
                },
              },
              [
                _c("div", [
                  _c("div", [
                    _c("video", {
                      ref: "video",
                      attrs: { autoplay: "", muted: "", width: "200px" },
                      domProps: { muted: true },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    disabled: _vm.isRecording,
                                  },
                                  on: { click: _vm.startRecording },
                                },
                                [_vm._v("开始录像")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    disabled: !_vm.isRecording,
                                  },
                                  on: { click: _vm.stopRecording },
                                },
                                [_vm._v("停止录像")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    disabled:
                                      !_vm.recordedBlob || _vm.isUploading,
                                  },
                                  on: { click: _vm.uploadRecording },
                                },
                                [_vm._v("上传录像")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.recordedBlob
                    ? _c("div", [_c("h3", [_vm._v("录像回放")])])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.videoStop },
                      },
                      [_vm._v("关 闭")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }