var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
    _vm.readyHint
      ? _c("div", { staticClass: "ready" }, [
          _vm._v("\n    " + _vm._s(_vm.down) + "\n    "),
          _c("div", [_vm._v("请准备朗读")]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.record
      ? _c("div", { staticClass: "teach_box" }, [
          _vm.teach_three
            ? _c(
                "div",
                {
                  staticClass: "teach_three",
                  on: {
                    click: function ($event) {
                      return _vm.teachThree()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/mxs_images/teach_three.png"),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.teach_four
            ? _c(
                "div",
                {
                  staticClass: "teach_four",
                  on: {
                    click: function ($event) {
                      return _vm.teachFour()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/mxs_images/teach_four.png"),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.teach_five
            ? _c(
                "div",
                {
                  staticClass: "teach_five",
                  on: {
                    click: function ($event) {
                      return _vm.teachFive()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/mxs_images/teach_five.png"),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ])
      : _c("div", { staticClass: "record_box", attrs: { id: "target" } }, [
          _c("div", { staticClass: "text" }, [
            _c("div", { staticClass: "text_title" }, [
              _vm._v("请读第" + _vm._s(_vm.paraIndex) + "段话"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              _vm._l(_vm.paragraph, function (item, index) {
                return _c("div", { key: index }, [
                  _vm.paraIndex === index + 1
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(item.content) },
                      })
                    : _vm._e(),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "paraIndex" }, [
              _c("span", [_vm._v(_vm._s(_vm.paraIndex))]),
              _vm._v(" / "),
              _c("span", [_vm._v(_vm._s(_vm.paragraph.length))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "startRecordBtn" }, [
              _vm.isRecording
                ? _c("div", [
                    _c("div", [
                      _c("span", { staticClass: "recording" }, [
                        _vm._v("录音中"),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "recordingIcon",
                        attrs: {
                          src: require("../../assets/mxs_images/recording.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "answer-footer-time" }, [
                        _vm._v(_vm._s(_vm.ti)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          !_vm.isRecording
            ? _c(
                "div",
                {
                  staticClass: "tapeClass",
                  on: {
                    click: function ($event) {
                      return _vm.ready(1)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "answer-footer-commit" }, [
                    !_vm.isRecording
                      ? _c("span", [_vm._v("开始朗读")])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isRecording && _vm.facialFlag
            ? _c(
                "div",
                {
                  staticClass: "botClass",
                  on: {
                    click: function ($event) {
                      return _vm.previous()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "answer-footer-commit" }, [
                    !_vm.isRecording
                      ? _c("span", [_vm._v("上一步")])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isRecording
            ? _c("div", { staticClass: "tapeClass" }, [
                _vm.paraIndex !== _vm.paragraph.length
                  ? _c(
                      "div",
                      {
                        staticClass: "answer-footer-commit",
                        on: {
                          click: function ($event) {
                            return _vm.ready(2)
                          },
                        },
                      },
                      [_c("span", [_vm._v("结束朗读")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.paraIndex === _vm.paragraph.length
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading.fullscreen.lock",
                            value: _vm.loading,
                            expression: "loading",
                            modifiers: { fullscreen: true, lock: true },
                          },
                        ],
                        staticClass: "answer-footer-commit",
                        staticStyle: {
                          "background-color": "#2CC74E",
                          color: "#ffffff",
                        },
                        on: {
                          click: function ($event) {
                            _vm.stopTimer()
                            _vm.next()
                          },
                        },
                      },
                      [_c("span", [_vm._v("下一步")])]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isRecording
            ? _c("div", { staticClass: "botClass" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      "text-align": "center",
                      "padding-right": "0px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.paraIndex !== 1,
                            expression: "paraIndex !== 1",
                          },
                        ],
                        staticClass: "rerecordPre",
                        on: {
                          click: function ($event) {
                            return _vm.ready(3)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/mxs_images/prePara.png"),
                            alt: "",
                            width: "100%",
                            height: "100%",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.paraIndex !== 1,
                            expression: "paraIndex !== 1",
                          },
                        ],
                        staticStyle: { color: "#ffffff" },
                      },
                      [_vm._v("重录上一段")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pause,
                        expression: "pause",
                      },
                    ],
                    staticStyle: { flex: "1", "text-align": "center" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rerecordNext",
                        on: {
                          click: function ($event) {
                            return _vm.pauseRecordingThe()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/mxs_images/pause.png"),
                            alt: "",
                            width: "100%",
                            height: "100%",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#ffffff" } }, [
                      _vm._v("暂停录音"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.resume,
                        expression: "resume",
                      },
                    ],
                    staticStyle: { flex: "1", "text-align": "center" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rerecordNext",
                        on: {
                          click: function ($event) {
                            return _vm.resumeRecordingThe()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/mxs_images/resume.png"),
                            alt: "",
                            width: "100%",
                            height: "100%",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#ffffff" } }, [
                      _vm._v("继续录音"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { flex: "1", "text-align": "center" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rerecordNext",
                        on: {
                          click: function ($event) {
                            return _vm.ready(4)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/mxs_images/thePara.png"),
                            alt: "",
                            width: "100%",
                            height: "100%",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#ffffff" } }, [
                      _vm._v("重录本段"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "100px" } }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }