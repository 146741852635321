var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _vm.dialogVisible
        ? _c("div", { staticClass: "agreement" }, [
            _c("div", { staticClass: "agreement_border" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("心理测试知情同意书"),
              ]),
              _vm._v(" "),
              _vm.noticeUrl !== null && _vm.noticeUrl !== undefined
                ? _c("div", { staticClass: "agreement_content" }, [
                    _c("iframe", {
                      staticStyle: { height: "100vh", border: "none" },
                      attrs: { width: "100%", src: _vm.noticeUrl },
                    }),
                  ])
                : _c("div", { staticClass: "agreement_content" }, [
                    _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                      _vm._v("尊敬的参与者："),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "欢迎您参加本次心理测试。为了确保您对测试的目的、过程和可能的影响有充分的了解，并保护您的权益，我们特制定本知情同意书。请您在参加测试前仔细阅读以下内容。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【一、测试目的】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "本次心理测试旨在了解您的心理状态、个性特点、情绪反应等方面的情况，为了解您的心理状况提供参考依据。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【二、测试内容】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "测试将包括一系列问题或任务，涉及您的个人经历、思想观念、情感体验等方面。测试的具体内容将根据测试的目的和类型而有所不同。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【三、测试方法】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "测试方法包含但不限于问卷调查、访谈、实验法、投射性测验等。您将按照测试指导完成测试任务。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【四、信息授权】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "为了更准确地分析您的心理状态，测试过程中需要了解您的个人信息（包含但不限于姓名、性别、年龄、手机号、身份证号、录音、录像等）。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【五、保密原则】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "您的个人信息及测试结果将被严格保密。平台将采取必要的措施保护您的个人信息与测试结果，不会透露给任何无关人员。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【六、自愿参与】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "您的参与是自愿的，您可以在任何时候决定退出测试，不会对您产生任何不良影响。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【七、可能的风险和不适】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "心理测试可能会引起一些情绪上的反应，如焦虑、紧张、回忆起不愉快的经历等。如果您在测试过程中感到不适，可以随时停止测试。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【八、测试结果的解释】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "1、任何测验都是有时限的，测试结果只是对您当前心理状态的一种评估。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "2、测验的结果会受到多方面的影响。个人的遗传特征、测验前的学习与经验以及测验情境的都会影响测验的成绩。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【九、结果应用的限定】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "1、测试报告不得复制、私自转让、盗用、冒用、涂改或其他形式篡改，均属无效。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "2、心理测评报告不能作为临床诊断结论。如有问题，请到专业医院做进一步检查。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v("3、心理测评报告不可用于司法鉴定用途。"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v("4、心理测评报告不能作为其他非法用途。"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph_text" }, [
                      _vm._v("【十、同意声明】"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "paragraph" }, [
                      _vm._v(
                        "我已仔细阅读并理解了上述内容，自愿参加本次心理测试。"
                      ),
                    ]),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dialog_footer" }, [
              _c(
                "button",
                {
                  staticClass: "agree_btn",
                  on: {
                    click: function ($event) {
                      return _vm.agreement()
                    },
                  },
                },
                [_vm._v("我已阅读并知晓")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.facial && _vm.facialFlag
        ? _c("Facial", {
            on: {
              record: _vm.checkRecord,
              facialData: function ($event) {
                return _vm.getFacialData($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.record && _vm.recordingFlag
        ? _c("Record", {
            attrs: { facialFlag: _vm.facialFlag },
            on: {
              answerPage: _vm.checkAnswerPage,
              facialPage: _vm.checkFacial,
              recordlData: function ($event) {
                return _vm.getRecordlData($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.answerPage && !_vm.dialogVisible
        ? _c(
            "div",
            { staticClass: "answerPage", attrs: { id: "target" } },
            [
              _c(
                _vm.nowAnswer.mediaType == 2
                  ? "audioHeader"
                  : _vm.nowAnswer.mediaType == 3
                  ? "videoHeader"
                  : _vm.nowAnswer.type != 9
                  ? "imgTextHeader"
                  : "",
                {
                  ref:
                    _vm.nowAnswer.mediaType == 2
                      ? "audioHeader"
                      : _vm.nowAnswer.mediaType == 3
                      ? "videoHeader"
                      : _vm.nowAnswer.type != 9
                      ? "imgTextHeader"
                      : "",
                  tag: "component",
                  attrs: { musicName: _vm.musicName, nowAnswer: _vm.nowAnswer },
                }
              ),
              _vm._v(" "),
              _c(
                _vm.nowAnswer.type == 0 &&
                  _vm.nowAnswer.title.indexOf("<img") == -1
                  ? "textTopic"
                  : _vm.nowAnswer.type == 0 &&
                    _vm.nowAnswer.title.indexOf("<img") != -1
                  ? "imageTopic"
                  : _vm.nowAnswer.type == 1
                  ? "multiTopic"
                  : _vm.nowAnswer.type == 2
                  ? "sortTopic"
                  : _vm.nowAnswer.type == 3
                  ? "weightTopic"
                  : _vm.nowAnswer.type == 4
                  ? "satisfactionTopic"
                  : _vm.nowAnswer.type == 5
                  ? "matrixTopic"
                  : "guideTopic",
                {
                  ref:
                    _vm.nowAnswer.type == 0 &&
                    _vm.nowAnswer.title.indexOf("<img") == -1
                      ? "textTopic"
                      : _vm.nowAnswer.type == 0 &&
                        _vm.nowAnswer.title.indexOf("<img") != -1
                      ? "imageTopic"
                      : _vm.nowAnswer.type == 1
                      ? "multiTopic"
                      : _vm.nowAnswer.type == 2
                      ? "sortTopic"
                      : _vm.nowAnswer.type == 3
                      ? "weightTopic"
                      : _vm.nowAnswer.type == 4
                      ? "satisfactionTopic"
                      : _vm.nowAnswer.type == 5
                      ? "matrixTopic"
                      : "guideTopic",
                  tag: "component",
                  attrs: {
                    chooseId: _vm.chooseId,
                    beforeTopic: _vm.beforeTopic,
                    nowAnswer: _vm.nowAnswer,
                    percenter: _vm.percenter,
                    percents: _vm.percents,
                  },
                  on: { chooseAnswer: _vm.chooseAnswer },
                }
              ),
              _vm._v(" "),
              _vm.isSubmit
                ? _c("div", { staticStyle: { height: "100px" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSubmit
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.fullscreen.lock",
                          value: _vm.loading,
                          expression: "loading",
                          modifiers: { fullscreen: true, lock: true },
                        },
                      ],
                      staticClass: "answer-footer-commitBox",
                      style: { zIndex: 3 },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    },
                    [_vm._m(0)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "answer-footer flex-space-between flex-row",
                  style: { zIndex: 3 },
                },
                [
                  _c("div", [
                    _vm.isBefore
                      ? _c(
                          "span",
                          {
                            staticClass: "answer-footer-btn",
                            on: {
                              click: function ($event) {
                                return _vm.before()
                              },
                            },
                          },
                          [_vm._v("上一题")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-row" }, [
                    _c("span", { staticClass: "answer-footer-number" }, [
                      _vm._v(_vm._s(_vm.percents) + "/"),
                      _c("span", { staticStyle: { color: "#b2b2b2" } }, [
                        _vm._v(_vm._s(_vm.percenter)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "answer-footer-time" }, [
                      _vm._v(_vm._s(_vm._f("watchTime")(_vm.seconds))),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "answer-footer-commit" }, [
      _c("span", [_vm._v("提交")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }