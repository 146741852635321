"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _evaluation = require("@/api/evaluation");
var _gaugePlan = require("@/api/gaugePlan");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _share = require("@/utils/share");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var FileSaver = require('file-saver')
var _default = exports.default = {
  data: function data() {
    return {
      chooseType: 1,
      headerList: [{
        name: '未完成',
        number: 0
      }, {
        name: '已完成',
        number: 0
      }],
      paidLogoList: [],
      completedLogoList: [],
      isTouchStart: false,
      pageNum: 0,
      pageNumTwo: 0,
      pageSize: 10,
      paidList: [],
      completedList: [],
      loading: true,
      tabIndex: 1,
      isIso: false
    };
  },
  filters: {
    watchTime: function watchTime(result) {
      var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      var m = Math.floor(result / 60 % 60) < 10 ? '0' + Math.floor(result / 60 % 60) : Math.floor(result / 60 % 60);
      var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
      if (Math.floor(result / 3600) <= 0) {
        return m + ':' + s;
      } else {
        return h + ':' + m + ':' + s;
      }
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  created: function created() {
    this.isIso = _utils.default.isIos();
    this.tabIndex = this.$route.query.tabIndex || 1;
    // this.openPaid()
    this.openCompleted();
  },
  components: {
    NoData: _NoData.default
  },
  methods: {
    //  筛选
    onTab: function onTab(index) {
      this.tabIndex = index;
      this.paidList = [];
      this.completedList = [];
      if (this.chooseType == 1) {
        this.openCompleted();
      } else {
        this.openPaid();
      }
    },
    //  下载报告
    onDowm: function onDowm(row) {
      // SHARE(
      //   location.href,
      //   {
      //     title: row.title,
      //     desc: row.subTitle,
      //     link:
      //       utils.host() +
      //       `/#/gauge/result?evaluationId=${row.id}`,
      //     imgUrl: row.thumbnail
      //   },
      //   function (res) {}
      // )
      (0, _gaugePlan.importDowm)(row.id).then(function (res) {
        var fileName = '报告.pdf';
        //console.log(fileName)
        // 获取文件名
        var url = res.content;
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName); // 文件名
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        }, 1000);
      }).catch(function (err) {});
    },
    chooseTitle: function chooseTitle(index) {
      this.chooseType = index;
      if (this.chooseType == 1) {
        this.openCompleted();
      } else {
        this.openPaid();
      }
    },
    resultClick: function resultClick(evaluationId) {
      this.$router.push({
        path: '/gauge/result',
        query: {
          evaluationId: evaluationId
        }
      });
    },
    answerClick: function answerClick(evaluationId, planId) {
      this.$router.push({
        path: '/gauge/answer',
        query: {
          evaluationId: evaluationId,
          planId: planId
        }
      });
    },
    //      未完成列表
    openPaid: function openPaid() {
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('evalStatus', 0);
      params.append('mchId', _this.mchId);
      params.append('source', _this.tabIndex);
      (0, _evaluation.list)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.paidList.push(item);
        });

        //            循环遍历得出进度
        _this.paidList.forEach(function (item) {
          var storage = localStorage.getItem('problem' + item.id);
          if (!_utils.default.isNull(storage)) {
            storage = JSON.parse(storage);
            item.eval = storage.length;
            item.evals = storage.length / item.total * 100;
          }
        });
        _this.headerList[0].number = response.data.total;
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    //      已完成列表
    openCompleted: function openCompleted() {
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNumTwo);
      params.append('pageSize', _this.pageSize);
      params.append('evalStatus', 1);
      params.append('mchId', _this.mchId);
      params.append('source', _this.tabIndex);
      (0, _evaluation.list)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.completedList.push(item);
        });
        _this.headerList[1].number = response.data.total;
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    loadmore: function loadmore() {
      if (this.chooseType == 0) {
        this.pageNum = this.pageNum + 1;
        this.openPaid();
      } else {
        this.pageNumTwo = this.pageNumTwo + 1;
        this.openCompleted();
      }
    },
    promptClick: function promptClick() {
      var _this = this;
      _this.$message({
        message: '功能尚未开发',
        offset: 120,
        center: true
      });
    },
    indexClick: function indexClick() {
      this.$router.push({
        path: '/'
      });
    },
    memberClick: function memberClick() {
      this.$router.push({
        path: '/member/member'
      });
    }
  }
};