import request from '@/utils/request';
export function list(params) {
  return request({
    url: '/mchGauge/list',
    method: 'get',
    params: params
  });
}
export function find(id) {
  return request({
    url: '/mchGauge/find/' + id,
    method: 'get'
  });
}
export function selectShowReportFlag(mchGaugeId) {
  return request({
    url: '/admin/mchGauge/showReportFlag/' + mchGaugeId,
    method: 'get'
  });
}