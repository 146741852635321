"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.answer = answer;
exports.columnCreate = columnCreate;
exports.counselorCreate = counselorCreate;
exports.count = count;
exports.courseCreate = courseCreate;
exports.create = create;
exports.find = find;
exports.findEval = findEval;
exports.list = list;
exports.question = question;
exports.update = update;
exports.uploadPhoto = uploadPhoto;
exports.uploadVideo = uploadVideo;
var _request = _interopRequireDefault(require("@/utils/request"));
function list(params) {
  return (0, _request.default)({
    url: '/member/evaluation/list',
    method: 'get',
    params: params
  });
}
function count(params) {
  return (0, _request.default)({
    url: '/member/evaluation/count',
    method: 'get',
    params: params
  });
}
function answer(data) {
  return (0, _request.default)({
    url: '/member/evaluation/answer',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/admin/evaluation/update',
    method: 'post',
    data: data
  });
}
function create(params) {
  return (0, _request.default)({
    url: '/member/evaluation/create',
    method: 'post',
    params: params
  });
}
function find(id) {
  return (0, _request.default)({
    url: '/member/evaluation/find/' + id,
    method: 'get'
  });
}
function findEval(id) {
  return (0, _request.default)({
    url: '/member/evaluation/find/' + id,
    method: 'get'
  });
}
function question(id) {
  return (0, _request.default)({
    url: '/member/evaluation/question/' + id,
    method: 'get'
  });
}
function courseCreate(params) {
  return (0, _request.default)({
    url: '/member/evaluation/courseCreate',
    method: 'post',
    params: params
  });
}
function columnCreate(params) {
  return (0, _request.default)({
    url: '/member/evaluation/columnCreate',
    method: 'post',
    params: params
  });
}
function counselorCreate(params) {
  return (0, _request.default)({
    url: '/member/evaluation/counselorCreate',
    method: 'post',
    params: params
  });
}
function uploadPhoto(params) {
  return (0, _request.default)({
    url: '/member/evaluation/uploadPhoto',
    method: 'post',
    data: params
  });
}
function uploadVideo(params) {
  return (0, _request.default)({
    url: '/member/evaluation/uploadVideo',
    method: 'post',
    data: params
  });
}