"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
// import Vue from 'vue'
// import Router from 'vue-router'
//
// Vue.use(Router)

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
    share:false                    如果页面需要开启右上角分享，请置为true
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/questionPage/autoLogin",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/questionPage/autoLogin"));
    });
  },
  hidden: true
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/newLogin",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/newLogin"));
    });
  },
  hidden: true
}, {
  path: "/facial",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/facial/index"));
    });
  },
  hidden: true
},
// {
//   path: "/serviceLogin",
//   component: () => import("@/views/login/serviceLogin"),
//   hidden: true
// },
{
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
},
// 自动登录信息采集
{
  path: "/infoCollect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/common/infoCollect"));
    });
  },
  hidden: true
},
// 完善资料
{
  path: "/material",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/common/material"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/404"));
    });
  },
  hidden: true
}, {
  path: "/gauge/index",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/index"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/gauge/record",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/record"));
    });
  },
  hidden: true
}, {
  path: "/gauge/answer",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/answer"));
    });
  },
  hidden: true
}, {
  path: "/gauge/result",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/result"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/gauge/order",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/order"));
    });
  },
  hidden: true
}, {
  path: "/gauge/info",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/info"));
    });
  },
  hidden: true
}, {
  path: "/gauge/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/gauge/list"));
    });
  },
  hidden: true
}, {
  path: "/course/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/course/list"));
    });
  },
  hidden: true
}, {
  path: "/course/info",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/course/info"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/course/success",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/course/success"));
    });
  },
  hidden: true
}, {
  path: "/course/courseItem",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/course/courseItem"));
    });
  },
  hidden: true
}, {
  path: "/course/courseDetails",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/course/courseDetails"));
    });
  },
  hidden: true
}, {
  path: "/member/bill",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/member/bill"));
    });
  },
  hidden: true
}, {
  path: "/member/task",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/member/task"));
    });
  },
  hidden: true
}, {
  path: "/member/setting",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/member/setting"));
    });
  },
  hidden: true
}, {
  path: "/course/order",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/course/order"));
    });
  },
  hidden: true
}, {
  path: "/payment",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/payment/index"));
    });
  },
  hidden: true
}, {
  path: "/article/order",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/article/order"));
    });
  },
  hidden: true
}, {
  path: "/article/index",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/article/index"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/article/payment",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/article/payment"));
    });
  },
  hidden: true
}, {
  path: "/article/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/article/list"));
    });
  },
  hidden: true
}, {
  path: "/counselor/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/list"));
    });
  },
  hidden: true
}, {
  path: "/counselor/steps",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/steps"));
    });
  },
  hidden: true
}, {
  path: "/counselor/order/orderList",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/order/orderList"));
    });
  },
  hidden: true
}, {
  path: "/counselor/info",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/info/info"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/counselor/second/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/components/second"));
    });
  },
  hidden: true
}, {
  path: "/counselor/third/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/components/third"));
    });
  },
  hidden: true
}, {
  path: "/counselor/fourth/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselor/components/fourth"));
    });
  },
  hidden: true
}, {
  path: "/counselorNew/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/list"));
    });
  },
  hidden: true
}, {
  path: "/counselorNew/steps",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/steps"));
    });
  },
  hidden: true
}, {
  path: "/counselorNew/order/orderList",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/order/orderList"));
    });
  },
  hidden: true
}, {
  path: "/counselorNew/info/info",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/info/info"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/counselorNew/info/case",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/info/case"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/counselorNew/task",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/task"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/counselorNew/second/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/components/second"));
    });
  },
  hidden: true
}, {
  path: "/counselorNew/third/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/components/third"));
    });
  },
  hidden: true
}, {
  path: "/counselorNew/fourth/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/counselorNew/components/fourth"));
    });
  },
  hidden: true
}, {
  path: "/",
  component: _layout.default,
  redirect: "/index",
  children: [{
    path: "index",
    name: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/index/index"));
      });
    },
    meta: {
      title: "首页",
      icon: "shouy",
      iconTwo: "shouye-3",
      share: true
    }
  }]
}, {
  path: "/category",
  component: _layout.default,
  redirect: "/category",
  children: [{
    path: "category",
    name: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/category/index"));
      });
    },
    meta: {
      title: "学习训练",
      icon: "faxianjihuo",
      iconTwo: "faxianjihuo-copy-copy-copy",
      permissions: ['discovery-index']
    }
  }]
}, {
  path: "/index/wxPage",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/index/wxPage"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/member",
  component: _layout.default,
  children: [{
    path: "member",
    name: "member",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/member/member"));
      });
    },
    meta: {
      title: "我的",
      icon: "wode",
      iconTwo: "wode1-copy"
    }
  }, {
    path: "mine",
    name: "mine",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/member/mine"));
      });
    },
    meta: {
      title: "我的",
      icon: "wode",
      iconTwo: "wode1-copy"
    },
    hidden: true
  }]
}, {
  path: "/binding/user",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/binding/user"));
    });
  },
  meta: {
    title: "用户绑定",
    share: true
  },
  hidden: true
}, {
  path: "/binding/counselor",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/binding/counselor"));
    });
  },
  meta: {
    title: "咨询师绑定",
    share: true
  },
  hidden: true
}, {
  path: "/distribution/promote",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/distribution/promote"));
    });
  },
  meta: {
    title: "推广中心",
    share: true
  },
  hidden: true
}, {
  path: "/distribution/memberDeal",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/distribution/memberDeal"));
    });
  },
  meta: {
    title: "累计客户"
  },
  hidden: true
}, {
  path: "/distribution/promoteOrder",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/distribution/promoteOrder"));
    });
  },
  meta: {
    title: "推广订单"
  },
  hidden: true
}, {
  path: "/distribution/promoteRules",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/distribution/promoteRules"));
    });
  },
  meta: {
    title: "推广规则"
  },
  hidden: true
}, {
  path: "/distribution/cash",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/distribution/cash"));
    });
  },
  meta: {
    title: "提现"
  },
  hidden: true
}, {
  path: "/distribution/cashSetup",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/distribution/cashSetup"));
    });
  },
  meta: {
    title: "提现设置"
  },
  hidden: true
}, {
  path: "/assessment/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/assessment/list"));
    });
  },
  meta: {
    title: "评估列表"
  },
  hidden: true
}, {
  path: "/assessment/list2021",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/assessment/list2021"));
    });
  },
  meta: {
    title: "评估列表"
  },
  hidden: true
}, {
  path: "/assessment/personalresult",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/assessment/personalResult"));
    });
  },
  meta: {
    title: "评估列表结果"
  },
  hidden: true
}, {
  path: "/assessment/personalList",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/assessment/personalList"));
    });
  },
  meta: {
    title: "评估列表(个人)"
  },
  hidden: true
}, {
  path: "/assessment/introduce",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/assessment/introduce"));
    });
  },
  meta: {
    title: "专题介绍"
  },
  hidden: true
}, {
  path: "/assessment/info",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/assessment/info"));
    });
  },
  meta: {
    title: "评估计划"
  },
  hidden: true
}, {
  path: "/activitySy/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/activitySy/list"));
    });
  },
  hidden: true
}, {
  path: "/activitySy/details",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/activitySy/details"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
}, {
  path: "/activitySy/learningRecord",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/activitySy/LearningRecords"));
    });
  },
  hidden: true
},
// 专栏分类
{
  path: "/column/columnCategery",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/column/columnCategery"));
    });
  },
  hidden: true
},
// 专栏报名
{
  path: "/column/columnSignUp",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/column/columnSignUp"));
    });
  },
  meta: {
    share: true
  },
  hidden: true
},
// 专栏阶段详情
{
  path: "/column/columnStageDetail",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/column/columnStageDetail"));
    });
  },
  hidden: true
},
// 专栏课程分阶段
{
  path: "/column/columnSubjectStage",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/column/columnSubjectStage"));
    });
  },
  hidden: true
},
// 专栏课程课程学习
{
  path: "/column/columnSubjectStudy",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/column/columnSubjectStudy"));
    });
  },
  hidden: true
},
//专栏订单列表
{
  path: "/column/columnOrder",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/column/columnOrder"));
    });
  },
  hidden: true
},
//管理员列表
{
  path: "/leave",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/member/leave"));
    });
  },
  hidden: true
},
//留言列表
{
  path: "/leavelist",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/member/leaveList"));
    });
  },
  hidden: true
},
//设置页面
{
  path: "/system",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/member/system"));
    });
  },
  hidden: true
},
// 页面开发中
{
  path: "/development",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/index/development"));
    });
  },
  hidden: true
},
// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;