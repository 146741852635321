var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.isH5 == "default"
        ? _c(
            "div",
            { staticClass: "login-container" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginRules,
                    "auto-complete": "on",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c("el-input", {
                        ref: "username",
                        attrs: {
                          placeholder: "请输入" + _vm.setName,
                          name: "username",
                          type: "text",
                          tabindex: "1",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password" } },
                            [
                              _c("el-input", {
                                ref: "verifyCode",
                                staticClass: "verifyCode",
                                attrs: {
                                  type: "text",
                                  tabindex: "2",
                                  name: "verifyCode",
                                  placeholder: "请输入" + _vm.setCode,
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "login-btn",
                      staticStyle: { width: "100%", "margin-bottom": "30px" },
                      attrs: { loading: _vm.loading, type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [_vm._v("登录")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.isH5 == "captcha"
        ? _c("service-login", {
            attrs: {
              title: _vm.title,
              setName: _vm.setName,
              setCode: _vm.setCode,
            },
          })
        : _vm.isH5 == "eval" ||
          (_vm.isH5 == "eval" &&
            _vm.system !== "http://admin.qhysd.com/api" &&
            !_vm.record)
        ? _c("eval-login", {
            attrs: {
              title: _vm.title,
              setName: _vm.setName,
              setCode: _vm.setCode,
            },
          })
        : _vm.isH5 == "eval" && _vm.system === "http://admin.qhysd.com/api"
        ? _c("ysdLogin", {
            attrs: {
              title: _vm.title,
              setName: _vm.setName,
              setCode: _vm.setCode,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-img" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/logo-img.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }