"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _gaugePlan = require("@/api/gaugePlan");
var _auth = require("@/utils/auth");
var _share = require("@/utils/share");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vant = require("vant");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      detailsTitle: '',
      detailsDate: '',
      detailsName: '',
      downloadFlag: '0',
      reportUrl: '',
      planId: '',
      details: '',
      isIso: false,
      showReportUrl: false,
      downLoading: false,
      show: false,
      hasPlanFlag: 0 //是否全部测评完成
    };
  },
  created: function created() {
    this.isIso = _utils.default.isIos();
    this.Init();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  filters: {
    dateTimeFormatter: function dateTimeFormatter(data) {
      if (!data) {
        return;
      }
      var dt = new Date(data);
      var yyyy = dt.getFullYear();
      var MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      var dd = dt.getDate().toString().padStart(2, '0');
      var h = dt.getHours().toString().padStart(2, '0');
      var m = dt.getMinutes().toString().padStart(2, '0');
      var s = dt.getSeconds().toString().padStart(2, '0');
      return yyyy + '年' + MM + '月' + dd + '日' + ' ' + h + ':' + m;
    }
  },
  methods: {
    //  报告下载
    omDown: function omDown() {
      var _this = this;
      // SHARE(
      //   location.href,
      //   {
      //     title: this.details.title,
      //     desc: this.details.subTitle,
      //     link:
      //       utils.host() + `/#/gauge/result?evaluationId=${this.details.id}`,
      //     imgUrl: this.details.thumbnail
      //   },
      //   function (res) {}
      // )
      _vant.Toast.loading({
        duration: 0,
        // 持续展示 toast
        forbidClick: true,
        message: '下载中...'
      });
      this.showReportUrl = false;
      this.downLoading = true;
      (0, _gaugePlan.importDowm)(this.planId).then(function (res) {
        _this.showReportUrl = true;
        _this.downLoading = false;
        var url = res.content;
        var fileName = '报告.pdf';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName); // 文件名
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        }, 1000);
        _vant.Toast.clear();
      }).catch(function (err) {});
    },
    onClickdwon: function onClickdwon() {
      var _this2 = this;
      this.$copyText(this.reportUrl).then(function (e) {
        _this2.$message({
          message: '报告下载地址已粘贴到剪切板，请复制到浏览器地址栏中下载!',
          type: 'success'
        });
      }).catch(function (e) {
        console.log('复制报告地址失败', e);
        _this2.$message({
          message: '该浏览器不支持自动复制',
          type: 'warning'
        });
      });
    },
    onIosDown: function onIosDown() {
      var _this3 = this;
      _vant.Toast.loading({
        duration: 0,
        // 持续展示 toast
        forbidClick: true,
        message: '下载中...'
      });
      this.showReportUrl = false;
      this.downLoading = true;
      (0, _gaugePlan.importDowm)(this.planId).then(function (res) {
        _this3.showReportUrl = true;
        _this3.downLoading = false;
        _this3.show = true;
        _this3.reportUrl = res.content;
        _vant.Toast.clear();
      }).catch(function (err) {});
    },
    Init: function Init() {
      var _this4 = this;
      if (this.$route.query.isTrue == 1) {
        //  团体
        (0, _gaugePlan.teamList)(this.$route.query.id).then(function (res) {
          _this4.hasPlanFlag = res.data.hasPlanFlag;
          //this.details = res.data.mchGaugeList[0]
          _this4.downloadFlag = res.data.downloadFlag;
          _this4.planId = res.data.id;
          _this4.detailsTitle = res.data.gauge ? res.data.gauge.subTitle : res.data.mchGaugeList[0].subTitle;
          _this4.detailsDate = res.data.companyEmployee.completeDate;
          // this.detailsName =
          //   res.data.companyEmployee.empName +
          //   '/' +
          //   res.data.companyEmployee.empNo
          if (_this4.mchId === '10260') {
            _this4.detailsName = res.data.companyEmployee.empName + '/' + res.data.companyEmployee.empNo.slice(-3);
          } else {
            _this4.detailsName = res.data.companyEmployee.empName + '/' + res.data.companyEmployee.empNo;
          }
        }).catch(function (err) {});
      } else {
        (0, _gaugePlan.perdList)(this.$route.query.id).then(function (res) {
          _this4.hasPlanFlag = res.data.hasPlanFlag;
          _this4.details = res.data.mchGaugeList[0];
          _this4.downloadFlag = res.data.downloadFlag;
          _this4.planId = res.data.id;
          _this4.detailsTitle = res.data.mchGaugeList[0].subTitle;
          _this4.detailsDate = res.data.companyEmployee.completeDate;
          // this.detailsName =
          //   res.data.companyEmployee.empName +
          //   '/' +
          //   res.data.companyEmployee.empNo
          if (_this4.mchId === '10260') {
            _this4.detailsName = res.data.companyEmployee.empName + '/' + res.data.companyEmployee.empNo.slice(-3);
          } else {
            _this4.detailsName = res.data.companyEmployee.empName + '/' + res.data.companyEmployee.empNo;
          }
        }).catch(function (err) {});
      }
    },
    onClick: function onClick(index) {
      if (index == 1) {
        (0, _auth.removeToken)();
        this.$router.replace({
          path: '/newLogin'
        });
        // this.$router.replace({
        //   path: '/'
        // })
      } else if (index == 2) {
        if (this.$route.query.isTrue == 1) {
          this.$router.replace({
            path: '/assessment/list'
          });
        } else {
          this.$router.replace({
            path: '/assessment/personalList'
          });
        }
      } else {
        this.$router.replace({
          path: '/'
        });
      }
    }
  }
};