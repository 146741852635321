import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import router from "../router";
import utils from '@/utils/utils';
// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// router.beforeEach(async (to, from, next) => {

//   var path = to.path + utils.mapToUrl(to.query)
//   console.log(path)
// })

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  console.log('token', store.getters.token);
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    console.log('带上了请求头');
    config.headers["Authorization"] = getToken();
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

  var res = response.data;
  if (res.type === "logout") {
    console.log(777, res.type);
    store.dispatch("user/resetToken").then(function () {
      var whiteList = ['/login', '/serviceLogin', '/auth-redirect', '/newLogin'];
      var inNUm = decodeURIComponent(location.hash).split('#');
      var redirectUrl = inNUm[1] || '/';
      var path = redirectUrl.split('?')[0];
      if (whiteList.indexOf(path) !== -1) {
        return;
      }
      console.log(redirectUrl);
      localStorage.removeItem('companyEmployee');
      // location.reload()
      if (utils.isWeiXin()) {
        if (store.getters.enableAuth != 1) {
          router.replace({
            path: '/auth-redirect?redirect=' + redirectUrl
          });
        } else {
          router.replace({
            path: '/newLogin?redirect=' + redirectUrl
          });
        }
      } else {
        router.replace({
          path: '/newLogin?redirect=' + redirectUrl
        });
      }
      // location.reload();
    });
  } else {
    if (res.type !== "error") {
      return res;
    } else {
      Message({
        message: res.content || "Error",
        type: "error",
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.content || "Error"));
    }
  }
}, function (error) {
  console.log("err" + error); // for debug
  Message({
    message: error.message,
    type: "error",
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;