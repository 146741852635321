"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _swipersChange = _interopRequireDefault(require("./components/shuffling/swipersChange"));
var _timeArticle = _interopRequireDefault(require("./components/timeArticle/timeArticle"));
var _articleImg = _interopRequireDefault(require("./components/articleImage/articleImg"));
var _search_focus = _interopRequireDefault(require("./components/search/search_focus"));
var _shuffling = _interopRequireDefault(require("./components/shuffling/shuffling"));
var _banner = _interopRequireDefault(require("./components/shuffling/banner"));
var _banner_two = _interopRequireDefault(require("./components/shuffling/banner_two"));
var _category = _interopRequireDefault(require("./components/category/category"));
var _category_three = _interopRequireDefault(require("./components/category/category_three"));
var _category_four = _interopRequireDefault(require("./components/category/category_four"));
var _category_five = _interopRequireDefault(require("./components/category/category_five"));
var _category_six = _interopRequireDefault(require("./components/category/category_six"));
var _category_eight = _interopRequireDefault(require("./components/category/category_eight"));
var _category_ten = _interopRequireDefault(require("./components/category/category_ten"));
var _category_bottom = _interopRequireDefault(require("./components/category/category_bottom"));
var _online_courses = _interopRequireDefault(require("./components/online_courses/online_courses"));
var _columns_courses = _interopRequireDefault(require("./components/online_courses/columns_courses"));
var _offline_courses = _interopRequireDefault(require("./components/offline_courses/offline_courses"));
var _counselor = _interopRequireDefault(require("./components/counselor/counselor"));
var _gauge = _interopRequireDefault(require("./components/gauge/gauge"));
var _consulting = _interopRequireDefault(require("./components/consulting/consulting"));
var _category_headerImg = _interopRequireDefault(require("./components/category/category_headerImg"));
var _search = _interopRequireDefault(require("./components/search/search"));
var _article = _interopRequireDefault(require("./components/article/article"));
var _single = _interopRequireDefault(require("./components/single/single"));
var _consultantQy = _interopRequireDefault(require("./components/consultantQy/consultantQy"));
var _consultantQyNoMore = _interopRequireDefault(require("./components/consultantQy/consultantQyNoMore"));
var _consultingOptions = _interopRequireDefault(require("./components/consultingOptions/consultingOptions"));
var _consultingOptionsSix = _interopRequireDefault(require("./components/consultingOptions/consultingOptionsSix"));
var _announcement = _interopRequireDefault(require("./components/announcement/announcement"));
var _activityComponent = _interopRequireDefault(require("./components/activityComponent/activityComponent"));
var _activityComponentMore = _interopRequireDefault(require("./components/activityComponent/activityComponentMore"));
var _columnTimeArticle = _interopRequireDefault(require("./components/columnComponents/columnTimeArticle"));
var _online_column = _interopRequireDefault(require("./components/columnComponents/online_column"));
var _columns_column = _interopRequireDefault(require("./components/columnComponents/columns_column"));
var _columnRecommend = _interopRequireDefault(require("./components/columnComponents/columnRecommend"));
var _masterhead = _interopRequireDefault(require("./components/counselor/masterhead"));
var _designer = require("@/api/designer");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _share = require("@/utils/share");
var _activityTimeArticle = _interopRequireDefault(require("./components/acticityTimeArticle/activityTimeArticle"));
var _coursesTimeArticle = _interopRequireDefault(require("./components/coursesTimeArticle/coursesTimeArticle"));
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _member = require("@/api/member");
var _singleIndex = _interopRequireDefault(require("./components/singleIndex/singleIndex"));
var _online_activity = _interopRequireDefault(require("./components/columnComponents/online_activity"));
var _columns_gauge = _interopRequireDefault(require("./components/columnComponents/columns_gauge"));
var _columns_activity = _interopRequireDefault(require("./components/columnComponents/columns_activity"));
var _gaugeArticle = _interopRequireDefault(require("./components/columnComponents/gaugeArticle"));
var _columnRdOld = _interopRequireDefault(require("./components/columnComponents/columnRdOld"));
var _articleRdOld = _interopRequireDefault(require("./components/columnComponents/articleRdOld"));
var _courseRdOld = _interopRequireDefault(require("./components/columnComponents/courseRdOld"));
var _gaugeRdOld = _interopRequireDefault(require("./components/columnComponents/gaugeRdOld"));
var _swiper = _interopRequireDefault(require("./components/shuffling/swiper"));
var _announcementStyle = _interopRequireDefault(require("./components/announcement/announcementStyle2"));
var _category_eight_style = _interopRequireDefault(require("./components/category/category_eight_style2"));
var _bannerStyle = _interopRequireDefault(require("./components/shuffling/bannerStyle2"));
var _banner_four = _interopRequireDefault(require("./components/shuffling/banner_four"));
var _swiper_courses = _interopRequireDefault(require("./components/online_courses/swiper_courses"));
var _gaugeStyle = _interopRequireDefault(require("./components/gauge/gaugeStyle2"));
var _activityStyle = _interopRequireDefault(require("./components/acticityTimeArticle/activityStyle2"));
var _counselorStyle = _interopRequireDefault(require("./components/counselor/counselorStyle2"));
var _activityComponentMoreStyle = _interopRequireDefault(require("./components/activityComponent/activityComponentMoreStyle2"));
var _swiperCard = _interopRequireDefault(require("./components/shuffling/swiperCard"));
var _category_ten_style = _interopRequireDefault(require("./components/category/category_ten_style2"));
var _announcementStyle2 = _interopRequireDefault(require("./components/announcement/announcementStyle3"));
var _bannerStyle2 = _interopRequireDefault(require("./components/shuffling/bannerStyle3"));
var _activityStyle2 = _interopRequireDefault(require("./components/acticityTimeArticle/activityStyle3"));
var _gaugeStyle2 = _interopRequireDefault(require("./components/gauge/gaugeStyle3"));
var _rolling_courses = _interopRequireDefault(require("./components/online_courses/rolling_courses"));
var _psychologyStyle = _interopRequireDefault(require("./components/psychology/psychologyStyle1"));
var _psychologyStyle2 = _interopRequireDefault(require("./components/psychology/psychologyStyle2"));
var _counselorStyle2 = _interopRequireDefault(require("./components/counselor/counselorStyle3"));
var _activityComponentMoreStyle2 = _interopRequireDefault(require("./components/activityComponent/activityComponentMoreStyle3"));
var _swiperStyle = _interopRequireDefault(require("./components/shuffling/swiperStyle2"));
var _category_eight_style2 = _interopRequireDefault(require("./components/category/category_eight_style3"));
var _announcementStyle3 = _interopRequireDefault(require("./components/announcement/announcementStyle4"));
var _bannerStyle3 = _interopRequireDefault(require("./components/shuffling/bannerStyle4"));
var _rolling_coursesStyle = _interopRequireDefault(require("./components/online_courses/rolling_coursesStyle2"));
var _activityStyle3 = _interopRequireDefault(require("./components/acticityTimeArticle/activityStyle4"));
var _rolling_activity = _interopRequireDefault(require("./components/acticityTimeArticle/rolling_activity"));
var _rolling_counselor = _interopRequireDefault(require("./components/counselor/rolling_counselor"));
var _activityComponentMoreStyle3 = _interopRequireDefault(require("./components/activityComponent/activityComponentMoreStyle4"));
var _gaugeStyle3 = _interopRequireDefault(require("./components/gauge/gaugeStyle4"));
var _headerBar = _interopRequireDefault(require("./components/headerBar/headerBar"));
var _announcementStyle4 = _interopRequireDefault(require("./components/announcement/announcementStyle5"));
var _category_eight_style3 = _interopRequireDefault(require("./components/category/category_eight_style4"));
var _announcementStyle5 = _interopRequireDefault(require("./components/announcement/announcementStyle6"));
var _banner_twoStyle = _interopRequireDefault(require("./components/shuffling/banner_twoStyle2"));
var _myTask = _interopRequireDefault(require("./components/task/myTask"));
var _gaugeStyle4 = _interopRequireDefault(require("./components/gauge/gaugeStyle5"));
var _image = _interopRequireDefault(require("@/components/Question/image.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Dashboard",
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["name", "mchId", "token", "xuid", "userId"])),
  data: function data() {
    return {
      componentsList: [],
      paramXuId: "",
      callMeDialogShow: false
    };
  },
  components: {
    timeArticle: _timeArticle.default,
    coursesTimeArticle: _coursesTimeArticle.default,
    articleImg: _articleImg.default,
    searchFocus: _search_focus.default,
    activityTimeArticle: _activityTimeArticle.default,
    shuffling: _shuffling.default,
    swipersChange: _swipersChange.default,
    category: _category.default,
    online_cuorses: _online_courses.default,
    orrline_courses: _offline_courses.default,
    gauge: _gauge.default,
    consulting: _consulting.default,
    category_headerImg: _category_headerImg.default,
    search: _search.default,
    NoData: _NoData.default,
    category_three: _category_three.default,
    category_four: _category_four.default,
    category_five: _category_five.default,
    category_six: _category_six.default,
    category_eight: _category_eight.default,
    category_ten: _category_ten.default,
    category_bottom: _category_bottom.default,
    activity: _article.default,
    single: _single.default,
    adBanner: _banner.default,
    adBanner_two: _banner_two.default,
    columns_courses: _columns_courses.default,
    counselor: _counselor.default,
    consultantQy: _consultantQy.default,
    consultantQyNoMore: _consultantQyNoMore.default,
    consultingOptions: _consultingOptions.default,
    consultingOptionsSix: _consultingOptionsSix.default,
    announcement: _announcement.default,
    activityComponent: _activityComponent.default,
    activityComponentMore: _activityComponentMore.default,
    columnTimeArticle: _columnTimeArticle.default,
    online_column: _online_column.default,
    columns_column: _columns_column.default,
    columnRecommend: _columnRecommend.default,
    masterhead: _masterhead.default,
    singleIndex: _singleIndex.default,
    online_activity: _online_activity.default,
    columns_gauge: _columns_gauge.default,
    columns_activity: _columns_activity.default,
    gaugeArticle: _gaugeArticle.default,
    columnRdOld: _columnRdOld.default,
    articleRdOld: _articleRdOld.default,
    courseRdOld: _courseRdOld.default,
    gaugeRdOld: _gaugeRdOld.default,
    swiper: _swiper.default,
    announcementStyle2: _announcementStyle.default,
    category_eight_style2: _category_eight_style.default,
    bannerStyle2: _bannerStyle.default,
    banner_four: _banner_four.default,
    swiper_courses: _swiper_courses.default,
    gaugeStyle2: _gaugeStyle.default,
    activityStyle2: _activityStyle.default,
    counselorStyle2: _counselorStyle.default,
    activityComponentMoreStyle2: _activityComponentMoreStyle.default,
    swiperCard: _swiperCard.default,
    category_ten_style2: _category_ten_style.default,
    announcementStyle3: _announcementStyle2.default,
    bannerStyle3: _bannerStyle2.default,
    activityStyle3: _activityStyle2.default,
    gaugeStyle3: _gaugeStyle2.default,
    rolling_courses: _rolling_courses.default,
    psychologyStyle1: _psychologyStyle.default,
    psychologyStyle2: _psychologyStyle2.default,
    counselorStyle3: _counselorStyle2.default,
    activityComponentMoreStyle3: _activityComponentMoreStyle2.default,
    swiperStyle2: _swiperStyle.default,
    category_eight_style3: _category_eight_style2.default,
    announcementStyle4: _announcementStyle3.default,
    bannerStyle4: _bannerStyle3.default,
    rolling_coursesStyle2: _rolling_coursesStyle.default,
    activityStyle4: _activityStyle3.default,
    rolling_activity: _rolling_activity.default,
    rolling_counselor: _rolling_counselor.default,
    activityComponentMoreStyle4: _activityComponentMoreStyle3.default,
    gaugeStyle4: _gaugeStyle3.default,
    headerBar: _headerBar.default,
    announcementStyle5: _announcementStyle4.default,
    category_eight_style4: _category_eight_style3.default,
    announcementStyle6: _announcementStyle5.default,
    banner_twoStyle2: _banner_twoStyle.default,
    myTask: _myTask.default,
    gaugeStyle5: _gaugeStyle4.default,
    Image: _image.default
  },
  created: function created() {
    this.loadGauge();
    (0, _share.SHARE)(location.href, {
      title: null,
      desc: null,
      link: _utils.default.host() + "/#/index?xuid=".concat(this.userId),
      imgUrl: null
    });
    this.getXuid();
  },
  methods: {
    goprison: function goprison() {
      // window.location.href = 'http://172.16.1.20:9003/#/assessment/list?mchId=18079'
      window.location.href = 'http://192.168.1.15:9003/#/assessment/list?mchId=18079';
    },
    goontest: function goontest() {
      this.$router.push({
        path: localStorage.getItem('testUrl'),
        query: {
          evaluationId: localStorage.getItem('testQuery')
        }
      });
    },
    getXuid: function getXuid() {
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch("user/setXuid", xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== "") {
        // 如果有token并且参数里有xuid

        (0, _member.lockSysUser)({
          xuid: this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
      console.log("xuid", this.xuid);
    },
    loadGauge: function loadGauge() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", this.mchId);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      (0, _designer.list)(params).then(function (response) {
        _this.componentsList = response.data;
      }).catch(function (error) {
        console.log("error!!");
      });
    }
  }
};